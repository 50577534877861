.auth-handler-container {
	text-align: center;
	background: #ffffff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	max-width: 400px;
	margin: auto;
  }
  
  .auth-content h2 {
	font-size: 1.5rem;
	color: #333;
	margin-bottom: 1rem;
  }
  
  .status-message {
	font-size: 1rem;
	color: #4caf50;
	margin-top: 1rem;
  }
  
  .error-message {
	font-size: 1rem;
	color: #e57373;
	margin-top: 1rem;
  }
  
  .fallback-message {
	font-size: 1rem;
	color: #777;
	margin-top: 1rem;
  }
  